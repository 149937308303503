<template>
  <div class="exercise-subpage">
    <div class="d-flex flex-column justify-content-center">

      <Entry title="The 505"
             img_name="the-505.png"
             yt_video_id="RNCZlGEvs9g"
             description="Go through the obstacles and finish off by running through the final gate."/>

      <Entry title="T-test"
             img_name="T-test.png"
             yt_video_id="yZup1FX4czo"
             description="From the starting line, sprint towards the middle then sideways, towards the right, and then to the otherside on the left. Back to the middle, than run backwards to the finish/starting line."/>

      <Entry title="5-10-15m shuttle"
             img_name="5-10-15-shuttle-sprint.png"
             yt_video_id="DsioqG8S4a8"
             description="From the starting line, sprint to the 5 station 5 meters away, then back to the base, then 10m, again back to base, finally run till the 15m line, and then back again to base to finish off."/>

      <Entry title="The Illinois"
             img_name="the-illinois.png"
             description="This exercise is familiar with the Drop-Out exercise and can be done with or without ball. One difference: The players in this exercise go alternately (one after another) in a specific order. When a SmartGoal lights up there is only one player that has to run. | Skill: acceleration, awareness, reaction speed | Coach on: dribbling in place, stand on front feet | Physical load: calfs, hamstrings, quadriceps | Keep them focussed!"/>

    </div>
  </div>
</template>

<script>
import Entry from "@/components/Entry";

export default {
  name: "ChangeOfDirectionPage",
  components: {
    Entry
  }
}
</script>
